import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';

import { motion, AnimatePresence } from 'framer-motion';

import { chooseFromFiles, fetchFile } from '../../app/fileManager';

import Box, { Flex } from '../Box';
import Button from '../RebassButton';
import Snackbar from '../Snackbar';
import Label from '../Label';
import { colors, lighten } from '../styles/colors';
import { P } from '../styles/typography';

import useHotkeys from '../../app/hooks/useHotkeys';
import FilterTag from './FilterTag';
import FileProps from '../editor/types/FileProps';
import { FileObjectProps } from '../../types/Browser';
import KitProps from '../../types/KitProps';
import AssetHits from './AssetHits';
import { FilterProps } from './Kit';

declare global {
  interface Window {
    ClipboardItem: any;
  }
}

const Container = styled.div`
  flex: 5;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const FiltersContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  padding: 10px;

  background-color: ${lighten(colors.grey, 55)};
  border-bottom: 1px solid ${colors['light-grey-50']};
`;

const ResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  overflow: auto;
`;

type Props = {
  files: FileProps[];
  selectFiles: (files: FileProps[], e: React.MouseEvent) => void;
  selectedFiles: FileProps[];
  handleDeselectFiles: () => void;
  uploadToKit: (files: FileObjectProps[]) => Promise<FileProps[]>;
  kit: KitProps;
  handleClearFilters: () => void;
  filters: FilterProps[];
  setFilters: (newFilters: FilterProps[]) => void;
  canEdit?: boolean;
  handleUploadFiles: (fileList: { [index: string]: File }) => Promise<void>;
};

function AssetsList(props: Props) {
  const {
    selectFiles,
    setFilters,
    selectedFiles,
    filters,
    handleDeselectFiles,
    handleUploadFiles,
    kit,
    handleClearFilters,
    canEdit,
  } = props;

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const canUpload = kit.name !== 'All';

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickResultsContainer = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleDeselectFiles();
  };

  const handleRemoveFilter = (filter: FilterProps) => {
    const newFilters = [...filters];
    const index = newFilters.findIndex((f) => f.value === filter.value);
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  const handleCopyToClipboard = async (file: FileProps) => {
    const buff = await fetchFile(file.data.url).then((blob) =>
      blob.arrayBuffer()
    );

    // Copy to clipboard as png
    const imageBlob = new Blob([buff], { type: `image/png` });
    const data = [new window.ClipboardItem({ [imageBlob.type]: imageBlob })];
    (window.navigator as any).clipboard.write(data); // FIXME: NOT FULLY IMPLEMENTED YET SO NO TYPES

    // Show snackbar
    setSnackbarMessage('Copied to clipboard');

    setTimeout(() => {
      setSnackbarMessage('');
    }, 3000);
  };

  useHotkeys([
    {
      // Copy to clipboard
      name: 'c',
      modifiers: ['cmd'],
      onPress: (e) => {
        if (selectedFiles.length === 1) {
          e.preventDefault();
          handleCopyToClipboard(selectedFiles[0]);
        }
      },
    },
  ]);

  const filterTags = filters.map((filter, i) => (
    <Flex key={filter.value} alignItems="center">
      <FilterTag filter={filter} handleRemoveFilter={handleRemoveFilter} />
      {i < filters.length - 1 && (
        <Box color="light-6" m={2}>
          {filters[i + 1].modifier || 'AND'}
        </Box>
      )}
    </Flex>
  ));

  const totalTags = filters.length;

  return (
    <Container ref={containerRef}>
      <Snackbar
        isVisible={!!snackbarMessage}
        style={{ background: colors.night }}
      >
        <Box
          style={{
            padding: '10px 20px',
          }}
        >
          <P inverted style={{ display: 'flex', alignItems: 'center' }}>
            {snackbarMessage}
          </P>
        </Box>
      </Snackbar>
      <AnimatePresence>
        <FiltersContainer ref={filterContainerRef}>
          <Box
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {filterTags}
            {totalTags > 0 && (
              <Box ml={1}>
                <Label color={'grey'} onClick={handleClearFilters}>
                  Clear
                </Label>
              </Box>
            )}
            {totalTags === 0 && (
              <P style={{ color: colors['light-grey-40'] }}>
                No filters applied
              </P>
            )}
          </Box>
          <Box>
            {canUpload && canEdit && (
              <Button
                onClick={() =>
                  chooseFromFiles(handleUploadFiles, { multiple: true })
                }
                variant="outline"
                color="dark-6"
                startIcon={<FontAwesomeIcon icon={['fad', 'file-upload']} />}
              >
                Upload files
              </Button>
            )}
          </Box>
        </FiltersContainer>
      </AnimatePresence>
      <ResultsContainer
        ref={scrollContainerRef}
        onClick={(e) => handleClickResultsContainer(e)}
      >
        <AssetHits
          cache={{
            read() {
              return {};
            },
            write() {},
          }}
          selectFiles={selectFiles}
          selectedFiles={selectedFiles}
          handleCopyToClipboard={handleCopyToClipboard}
        />
      </ResultsContainer>
    </Container>
  );
}

AssetsList.defaultProps = {
  selectedFiles: [],
  files: [],
  canEdit: true,
};

export default AssetsList;
